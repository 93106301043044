import React from "react"
import * as Adm from "@adm"
import { Grid, Typography, TextField, Tabs, Tab } from "@material-ui/core"
import * as Render from "./renderProps"
import { useConfigUI } from "./useConfigUI"
import { TabPanel } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import AvalaraFormFields from "./AvalaraFormFields"
import { useTranslation } from "react-i18next"
export const useStyles = makeStyles((theme) => ({
  tab: {
    minHeight: "40px !important",
    "& .MuiTab-wrapper": {
      fontSize: "16px",
    },
    "& .MuiTabs-fixed": {
      // height: "40px",
    },
    "& .MuiTab-textColorInherit": {
      color: "#FFFFFF",
      backgroundColor: "gray",
      borderRadius: "20px 20px 0px 0px",
      margin: "0px 5px",
      padding: "0px 15px",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#d38bb9",
      color: "#FFFFFF",
      borderRadius: "20px 20px 0px 0px",
    },
  },
  gridStyle: {
    padding: "20px",
    border: "1px solid #aeacac",
    borderRadius: "15px",
    margin: "0px -10px",
  },
}))

function TaxConnectorTabs(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    stateConfigUIProps = {},
    crudType = "",
    type = "",
    getAvalaraDetails = () => { },
  } = props || {}

  const {
    setCurrentTab = () => { },
    currentTab = "",
    onChangeValueTextField = () => { },
  } = stateConfigUIProps

  const handleChange = (e, newValue) => {
    console.log("tab value", newValue)
    setCurrentTab(newValue)
    onChangeValueTextField({
      value: newValue,
      fieldName: "currentTab",
    })
  }

  let displayData = ""
  switch (currentTab) {
    case "tax":
      displayData = (
        <Grid className={classes.gridStyle}>
          <AvalaraFormFields
            stateConfigUIProps={stateConfigUIProps}
            crudType={crudType}
            type={type}
            getAvalaraDetails={getAvalaraDetails}
            currentTab={currentTab}
          />
        </Grid>
      )
      break
    case "price":
      displayData = (
        <Grid className={classes.gridStyle}>
          <AvalaraFormFields
            stateConfigUIProps={stateConfigUIProps}
            crudType={crudType}
            type={type}
            getAvalaraDetails={getAvalaraDetails}
            currentTab={currentTab}
          />
        </Grid>
      )
      break
    case "promotion":
      displayData = (
        <Grid className={classes.gridStyle}>
          <AvalaraFormFields
            stateConfigUIProps={stateConfigUIProps}
            crudType={crudType}
            type={type}
            getAvalaraDetails={getAvalaraDetails}
            currentTab={currentTab}
          />
        </Grid>
      )
      break
    case "pms":
      displayData = (
        <Grid className={classes.gridStyle}>
          <AvalaraFormFields
            stateConfigUIProps={stateConfigUIProps}
            crudType={crudType}
            type={type}
            getAvalaraDetails={getAvalaraDetails}
            currentTab={currentTab}
          />
        </Grid>
      )
      break
    default:
      break
  }

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        indicatorColor=""
        textColor="inherit"
        aria-label="full width tabs example"
        className={classes.tab}
      >
        <Tab label={t("TAX CONFIG")} value="tax" />
        <Tab label={t("PRICE CONFIG")} value="price" />
        <Tab label={t("PROMOTION CONFIG")} value="promotion" />
        <Tab label={t("PMS CONFIG")} value="pms" />
      </Tabs>
      {displayData}
    </>
  )
}

export { TaxConnectorTabs }
export default TaxConnectorTabs
